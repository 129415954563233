@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}
@keyframes bounce-up-down {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-bounce-up-down {
  animation: bounce-up-down 1s ease-in-out infinite;
}

.blur-sm {
  backdrop-filter: blur(100px);
}

.modal-content {
  backdrop-filter: none;
}
