.masonry-grid {
  column-count: 3;
  column-gap: 15px;
}

.masonry-item {
  break-inside: avoid-column;
  position: relative;
  margin-bottom: 15px;
}

.masonry-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

.masonry-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.masonry-item:hover .masonry-overlay {
  opacity: 1;
}

.masonry-text {
  color: white;
  text-align: center;
  padding: 10px;
}

@media (max-width: 767px) {
  .masonry-grid {
    column-count: 2; 
    column-gap: 5px;
    padding: 5px;
  }

  .masonry-item {
    margin-bottom: 5px;
  }
}